import React, {Component} from 'react';
import { Route } from 'react-router-dom';
import APIData from './modules/apiData/APIData';
import { crudActions } from "../services/crudActions";

import EmptyScreen from './EmptyScreen';
import GenerateReport from './modules/generateReport/GenerateReport';
import MyAccount from './modules/myAccount/MyAccount';
import AffiliateLinks from './modules/affiliateLinks/AffiliateLinks';

import { Row } from 'simple-flexbox';
import { FETCH_PERMISSIONS } from '../actions/types';
import MyCommission from "./modules/myAccount/MyCommission";
const combineReducers =  require('../reducers/index');

class Module extends Component {
  state = {
    permissions: [],
    roleId: ""
  };

  componentDidMount() {
    crudActions.get(`v1/myaccount/me`).then(
      (user) => {
        if (user) {
          combineReducers.default.dispatch({
            type: FETCH_PERMISSIONS,
            payload: user.permissions,
            roleId: user.roleId,
            agentType: user.agentType
          });
          this.setState({
            permissions: user.permissions,
            roleId: user.roleId
          });
        }
      }
    ).catch(
      err => {
        localStorage.removeItem("loginAsUser");
        localStorage.removeItem("loginAsUserEmail");
        if (err && err.message) {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: err.message,
          });
        }
      }
    );
  };

  checkPageAccess = (permissionName) => {
    const { permissions } = this.state;
    const foundPermission = permissions.find(elem => elem.permission === permissionName);
    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  checkPageAccessCategory = (categoryName) => {
    const { permissions } = this.state;
    const foundPermissions = permissions.filter(elem => elem.category === categoryName);
    return foundPermissions.some(elem => elem.state);
  };

  checkRole = (roleName) => {
    const { roleId } = this.state;
    return roleId === roleName;
  };

  render() {
    return (
      <Row flexGrow={ 1 }>
        <Route exact path="/" component={ EmptyScreen }/>
        { this.checkPageAccessCategory("REPORTS") && <Route exact path="/generate-reports" component={ GenerateReport }/> }
        <Route exact path="/my-account" component={ MyAccount }/>
        <Route exact path="/my-commission" component={ MyCommission }/>
        <Route exact path="/referral-links" component={ AffiliateLinks }/>
        <Route exact path="/dashboard" component={ APIData }/>
      </Row>
    );
  }
}

export default Module;